<template>
  <v-row class="fill-height">
    <v-col  >
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="12" xl="12">
          <v-card>
            <v-card-title>
              <v-icon class="mr-2">mdi-format-list-bulleted-square</v-icon>
              Lista de categorias 
              <v-spacer></v-spacer>
              <v-text-field
                class="mr-3"
                append-icon="mdi-magnify"
                label="Busca"
                single-line
                hide-details
              />
              <v-spacer></v-spacer>
              <v-btn color="success" :to="{ name: 'categoriesAdd' }">
                <v-icon left>mdi-plus-thick</v-icon>
                Nova categoria
              </v-btn>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col md="12">
          <v-row justify="center">
            <v-col md="12" lg="12" sm="12">
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="categories"
                item-key="id"
                class="elevation-1"
                
              >
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        :to="{
                          name: 'categoriesEdit',
                          params: { id: item.id }
                        }"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { CATEGORIES_GET, } from "@/store/actions/categories.type";
//import { CATEGORY_RESET_STATE } from "@/store/mutations/categories.type";

export default {
  name: "categoriesList",

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    // await store.dispatch(CATEGORY_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
      await store.dispatch(CATEGORIES_GET)
    return next();
  },

  data: () => ({
    selected: [],
    headers: [
      { text: "Nome", align: "center", value: "name" },
      { text: "Valor", align: "center", value: "value" },
      { text: "Editar", align: "center", value: "actions", sortable: false }
    ],
  }),
  mounted() {
    console.log("categoriesList mounted",this.categories);
  },
  beforeMount(){
        console.log("categoriesList mounted",this.categories);
  },
  methods: {
    //Methods Lista Usuarios
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.dados.slice();
    },
    categoryFormValidation(){
      return this.$refs.categoryForm.validate();
    }
  },

  computed: {
    ...mapGetters(["categories", "checkUsers"])
  }
};
</script>
